import React from 'react';
import { PopupButton } from '@typeform/embed-react'

import hulp_logo from '../assets/img/hulp_logo.svg'
import sketch from '../assets/img/sketch.svg'
import mail_icon from '../assets/img/mail.svg'

function Home() {
  return (
    <div>
      <section>
        <div className='uk-container uk-padding uk-padding-remove-vertical' style={{ height: '100vh' }}>
          <div data-uk-grid className='uk-nav-bar'>
            <div className="uk-width-expand">
              <img className='hulp-logo' src={hulp_logo} alt='Hulp Logo' />
            </div>
            <div className="uk-width-auto"> <a class="uk-button uk-button-contact" href="mailto:hulp_fund@aslanweb.co?subject= I want to learn more about Hulp!">
              <img className='mail-btn-icon' src={mail_icon} alt='icon' /> Contact</a>
            </div>
          </div>

          <div className='landing-curved-bg uk-flex'>
            <div className='landing-curved-content'>
              <div data-uk-grid >
                <div className="uk-width-3-5@m">
                  <h1 className='uk-landing-h1'>raise funds for <span>anything</span></h1>
                  <p className='uk-landing-subheading'>We're building the best tools to help empower
                    you to successfully raise funds</p>
                  <PopupButton id="VRUzyaQq" className="uk-button uk-landing-cta-button">
                    Request early access <span role="img" aria-label='emoji'>✌🏽</span>
                  </PopupButton>
                  <p className='uk-landing-meta-text'><span>*</span>we promise, we won't spam you</p>
                </div>
                <div className="uk-width-2-5@m uk-visible@m">
                  <img src={sketch} alt='Illustrative sketch of payment methods' />
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
}

export default Home;
